@font-face {
  font-family: Planet Gamers;
  src: url("planet-gamers.6e4676b6.otf") format("woff2");
}

body, html {
  height: 100%;
  scrollbar-gutter: stable;
  margin: 0;
  padding: 0;
  font-family: Ubuntu;
}

html {
  background: #354672;
}

body {
  min-height: 100%;
}

a {
  color: #e5aa18;
  font-weight: bold;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/*# sourceMappingURL=index.b02e1f93.css.map */
