@font-face {
  font-family: "Planet Gamers";
  src: url("./planet-gamers.otf") format("woff2");
}

body,
html {
  padding: 0px;
  margin: 0px;
  height: 100%;

  font-family: Ubuntu;
  scrollbar-gutter: stable;
}

html {
  background: rgba(53,70,114);
}

/* Space for navigation bar */
body {
  min-height: 100%;
}

a {
  text-decoration: none;
  color: #e5aa18;
  font-weight: bold;
}

a:hover {
  text-decoration: underline;
}
